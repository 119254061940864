var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-main-slider mb-5" },
    [
      _c(
        "swiper",
        { ref: "swiperRef", attrs: { options: _vm.swiperOption } },
        _vm._l(_vm.categories, function(subCategory) {
          return _c(
            "swiper-slide",
            { key: subCategory.categoryId },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    elevation: "0",
                    flat: "",
                    to: {
                      name: "Category",
                      params: { pathMatch: subCategory.slug }
                    }
                  }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.src(subCategory),
                      alt: subCategory.name,
                      contain: "",
                      onerror: "this.onerror=null;this.src='/no-icon.png'",
                      width: "200",
                      height: "200"
                    }
                  }),
                  _c(
                    "h2",
                    { staticClass: "text--secondary subcategory-name" },
                    [_vm._v(" " + _vm._s(subCategory.name) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm.showBullets && _vm.categories.length > 1
        ? _c("div", {
            staticClass: "swiper-pagination",
            attrs: { id: `pagination-${_vm.paginationClass}` }
          })
        : _vm._e(),
      _vm.showArrows && _vm.categories.length > 1
        ? _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { id: `slider-prev-${_vm.paginationClass}` }
          })
        : _vm._e(),
      _vm.showArrows && _vm.categories.length > 1
        ? _c("div", {
            staticClass: "swiper-button-next",
            attrs: { id: `slider-next-${_vm.paginationClass}` }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }